<template>
  <div class="news">
    <p class="news_title">最新新闻</p>
    <div class="news_main clear">
      <img class="news_img" src="../assets/img/indexImg/hotSpot.png" alt="">
      <div class="news_info">
        <p class="time">{{ data.date }}</p>
        <p class="title_info">{{ data.title }}</p>
        <p class="info_txt">{{data.content}}</p>
        <a title="点击可以跳转" class="submit" href="javascript:;" @click="Jump('0')"> <span ></span>阅读全文 </a>
      </div>
    </div>
    <div class="more_new">
      <div class="title">最新热点</div>
      <ul class="list clear">
        <li class="postion_li">
          <img src="../assets/img/indexImg/hotSpot.png" alt="" class="img">
          <div class="info">
            <p class="txt">{{ data.title }} </p>
            <p class="describe">
              {{ data.content }}
              </p>
              <img src="../assets/img/indexImg/link1.png" alt="" class="link_one" @click="Jump('0')">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two" @click="Jump('0')">
          </div>
          
        </li>
        <li class="listShow postion_li">
          <img src="../assets/img/indexImg/hotSpot2.png" alt=""  class="img">
          <div class="info">
            <p class="txt">{{ data_IDTWO.title }}</p>
            <p class="describe">{{ data_IDTWO.content }}</p>
            <img src="../assets/img/indexImg/link1.png" alt="" class="link_one" @click="Jump('1')">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two" @click="Jump('1')">
          </div>
        </li>
        <li class="postion_li">
          <img src="../assets/img/branch/IT.jpg" alt=""  class="img">
          <div class="info">
            <p class="txt">{{ data_IDTHREE.title }} </p>
            <p class="describe">{{data_IDTHREE.content}}</p>
            <img src="../assets/img/indexImg/link1.png" alt="" class="link_one" @click="Jump('2')">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two" @click="Jump('2')">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "",
  props: {},
  components: {  },
  data() {
    return {
        object:{},
        data:{date:'2024年1月5日',title:'工业智能化背景下制造业大数据的应用',content:'随着科学技术的高速发展和广泛应用，工业智能化背景下的大数据应用成为制造业转型发展的重要工具，是工业改革和产业革命的必然趋势。'},
        data_IDONE:{date:'2024年1月6日',title:'工业智能化背景下制造业大数据的应用',content:'随着科学技术的高速发展和广泛应用，工业智能化背景下的大数据应用成为制造业转型发展的重要工具，是工业改革和产业革命的必然趋势。'},
        data_IDTWO:{date:'2024年1月7日',title:'JHRON(聚和荣科技)是一家成立于北京海淀的中国计算机软',content:'在当今快速发展的技术世界中，编程语言的重要性不言而喻。Java和Python是两种流行的编程语言，它们在软件开发、数据分……'},
        data_IDTHREE:{date:'2024年1月8日',title:'中国最厉害的软件开发公司排名排名榜前十',content:'华盛恒辉、五木恒润、北京华盛恒辉、北京五木恒润、中科软、博彦科技、浪潮、亚信科技、新致软件。'},
    };  
  },
  methods: {
    Jump(index){

      if(index=='0'){
       localStorage.setItem('data',JSON.stringify(this.data)  )
      }
      if(index=='1'){
        localStorage.setItem('data',  JSON.stringify(this.data_IDTWO))
      }
      if(index=='2'){
        localStorage.setItem('data', JSON.stringify(this.data_IDTHREE))
      }
      this.$router.push({path:'/details'})
    }
  },

  created() { },

  mounted() { },

  computed: {},

  watch: {},
};
</script>

<style scoped>
@import url('../assets/css/news.css');
</style>